import { config } from "./config";
let old:{
  debug: (message?: any, ...optionalParams: any[]) => void | undefined;
  warn: (message?: any, ...optionalParams: any[]) => void | undefined;
  error: (message?: any, ...optionalParams: any[]) => void | undefined;
  info: (message?: any, ...optionalParams: any[]) => void | undefined;
  log: (message?: any, ...optionalParams: any[]) => void | undefined;
} = {
  debug: console.debug,
  warn: console.warn,
  error: console.error, 
  info: console.info,
  log: console.log,
}
  let debug_mode = false;

const not_input = (message?: any, ...optionalParams: any[]): void => {
  // if (old_debug != undefined) old_debug("debug ;)");
};

export const redirectConsole = async () => {
  console.log('Monomax Office app');
  console.log('author: Dmitriy Pososhin ');
  console.log('Telegram: https://t.me/possoshin ');
  console.log(`version: ${config.version.version} (${config.version.gitHash})`)
  if (config && config.debug) debug_mode = true;
  console.debug(config);
  if (debug_mode){
    console.log(config.version)
  } 
  if (!debug_mode) {
    for(let key in old ){
      if(old[key] && console[key])
        console[key] = not_input;
    }
  }
};
