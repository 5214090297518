export function sendJSON2Post(get): Promise<any> {
  return new Promise((res, rej) => {
    let xhr = new XMLHttpRequest();
    let formData = new FormData();
    xhr.open("POST", `/${get}`, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    formData.append("get", "config");
    xhr.onload = function () {
      try {
        res(JSON.parse(xhr.response));
      } catch (error) {
        console.error(error);
        rej("loading error");
      }
    };
    xhr.send();
  });
}
