import "./css/launcher.scss";
import { loadConfig, config } from "./config";
import { redirectConsole } from "./console"
import "./js/parallax"

function runConfig() {
  return import("./config")
    .then(({ default: _ }) => {})
    .catch((error) => "An error occurred while loading the Config");
}
function runApp() {
  return import("../index")
    .then(({ default: _ }) => {
      return;
    })
    .catch((error) => "An error occurred while loading the App");
}

global.configApp = {};
const eLauncher = document.querySelector(".launcher");
const title = eLauncher.querySelector(".title");
const text = eLauncher.querySelector(".text");

title.innerHTML = "Офис 3";
text.innerHTML = "Load...";
runConfig()
  .then((_) => {
    return loadConfig();
  })
  .then((_) => {
    return redirectConsole();
  })
  .then((_) => {
    return runApp();
  })
  .then((_) => {
    eLauncher.remove();
  })
  .catch((e) => {
    console.error(e);
    text.classList.add("error");
    text.innerHTML = e;
  });
