"use strict";
window.onload = function () {
  const parallax = document.querySelector(".launcher");
  if (parallax) {
    const content = document.querySelector(".parallax__container");
    const images = [
      document.querySelector(".title"),
      document.querySelector(".picture-01"),
    ];

    const forImages = [30, 10];
    const forScroll = [0.8, 1];

    const speed = 0.03;
    let positionX = 0;
    let positionY = 0;
    let coordXPercent = 0;
    let coordYPercent = 0;

    let scrollTopPercent = (window.pageYOffset / parallax.offsetWidth) * 100;

    const setMouseStyle = () => {
      const distX = coordXPercent - positionX;
      const distY = coordYPercent - positionY;

      positionX += distX * speed;
      positionY += distY * speed;
      images.forEach(
        (image, i) =>
          (image.style.cssText = `transform: translate(${
            positionX / forImages[i]
          }%,${positionY / forImages[i] - scrollTopPercent * forScroll[i]}%)`)
      );
      requestAnimationFrame(setMouseStyle);
    };
    setMouseStyle();
    parallax.addEventListener("mousemove", (e) => {
      const parallaxWidth = parallax.offsetWidth;
      const parallaxHeight = parallax.offsetHeight;
      const coordX = e.pageX - parallaxWidth / 2;
      const coordY = e.pageY - parallaxHeight / 2;
      coordXPercent = (coordX / parallaxWidth) * -100;
      coordYPercent = (coordY / parallaxHeight) * -100;
    });
  }
};
